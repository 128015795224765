exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-privacy-legal-tsx": () => import("./../../../src/pages/privacy-legal.tsx" /* webpackChunkName: "component---src-pages-privacy-legal-tsx" */),
  "component---src-pages-product-tsx": () => import("./../../../src/pages/product.tsx" /* webpackChunkName: "component---src-pages-product-tsx" */),
  "component---src-pages-technology-tsx": () => import("./../../../src/pages/technology.tsx" /* webpackChunkName: "component---src-pages-technology-tsx" */),
  "component---src-templates-news-art-tsx": () => import("./../../../src/templates/newsArt.tsx" /* webpackChunkName: "component---src-templates-news-art-tsx" */)
}

